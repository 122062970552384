.boldtext{
    font-weight:700;
    padding-bottom:10px; 
    color:#323232;
}

.boldtext2{
    font-weight:700;
    color:#323232;
}

.backText{
    width:100%;
    border:1px solid #dcdcdc;
    box-sizing: border-box;
    padding:10px;
    display: flex;
    flex:1;
    flex-direction: row;
    align-items: center;
}

.backText span{
    display: flex;
    flex:1;
}

.backText img{
    width:13px;
    height:13px;
}



.dialogClause {
    position:fixed;
    display:flex;
    flex-direction:column;
    bottom:0;
    left:0;
    width:100vw;
    min-width: 100vw;
    max-width: 100vw;
    height:100vh;
    min-height: 100vh;
    max-height: 100vh;
    background-color: #00000070;
    align-items: center;
    justify-content: center;
 }

 .dialogClauseIn {
     height:fit-content;
     width:70vw;
     background-color: #ffffff;
 }

 .dialogClauseIn div{
     padding:12px;
 }
