.portfolioList{
    width: 870px;
    max-width: 870px;
    box-sizing: border-box; 
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;  
    grid-column-gap:10px; 
    grid-row-gap:10px;
    overflow:scroll;
    margin-bottom: 30px;
}

.bPortfolioList{
    width: 1170px;
    max-width: 1170px;
    box-sizing: border-box; 
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;  
    grid-column-gap:10px; 
    grid-row-gap:10px;
    overflow:scroll;
    margin-bottom: 30px;
}

.mPortfolioList{
    width: calc(100vw - 30px);
    max-width: calc(100vw - 30px);
    box-sizing: border-box; 
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.portfolioList span{
    width: 425px;
    min-width: 425px;
    max-width: 425px;
    height: 318px;
    /* 425px * 0.56 + 80px */
    min-height: 318px; 
}

.bPortfolioList span{
    width: 575px;
    min-width: 575px;
    max-width: 575px;
    height: 402px;
       /* 575px * 0.56 + 80px */
    min-height: 402px; 
}

.mPortfolioList span{
    width: calc(100vw - 40px);
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
    height: calc((100vw - 40px) *0.56 + 80px);
    min-height: calc((100vw - 40px) *0.56 + 80px);
    margin-bottom: 15px; 
} 



.portfolioSwiper{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: flex;  
    align-items: center;
    justify-content: center;

}

 



.mobileApp .portfolioSwiperSlide{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    /* height: 100%;   */
    /* height: calc(100vh - 150px); */
    /* margin-top:150px; */

    height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* transform:scale(1,1);   */
}
.portfolioSwiperSlide{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    /* height: 100%;   */
    /* height: calc(100vh - 150px); */
    /* margin-top:150px; */

    height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* transform:scale(0.9,0.9);   */
    
}
 
.portfolioSwiperSlideImg{
    width: calc(100% - 40px);
    max-width: 1024px;
    min-height: fit-content;
    max-height: calc(100% - 90px);
    border: 1px solid #dcdcdc50;
    /* border-radius: 10px; */
    margin:10px 20px 10px 20px;
    height: auto;
    object-fit: cover;  
    object-position: top;  
}

.portfolioContentsLayout{
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    width: calc(100% - 40px);  
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; 
}
.portfolioItemContentsTitle div:nth-child(1){
    font-size: 16px;
    display: flex;
    flex:1;
    text-align: left;
}

.portfolioItemContentsTitle div:nth-child(2){
    display: flex;
    justify-content: flex-end;
    min-width: fit-content;
}

.portfolioItemContentsInfo{
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
}

.portfolioItemContentsInfo div:nth-child(1){  
    font-size: 14px;
    color: #969696;
    text-align: left;
    overflow:hidden;
    text-overflow:ellipsis;
    text-align: center; 
}
.portfolioItemContentsInfo div:nth-child(2){
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    flex:1;
    min-width: fit-content;
}

.portfolioItemContentsInfo img{
    width: 25px;
    height: 25px;
    margin-left: 5px;
}




 
.portfolioImageDiv{
    width: 100%;
    min-width:100%;
    max-width: 100%;  

    height: 55vw;
    min-height: 55vw;
    max-height: 55vw;  
    position: relative;
    transform:scale(0.93,0.93); 
} 
 


.skillList{
    padding-top:30px;
    width: 870px;
    max-width: 870px;
    box-sizing: border-box; 
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;  
    grid-column-gap:10px; 
    grid-row-gap:10px;
    overflow:scroll;
    margin-bottom: 30px;
}
 
.mSkillList{
    padding-top:10px;
    width: calc(100vw - 30px);
    max-width: calc(100vw - 30px);
    box-sizing: border-box; 
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;  
    grid-column-gap:10px; 
    grid-row-gap:10px; 
    /* margin-bottom: 30px; */
}

.bSkillList{
    padding-top:30px;
    width: 1170px;
    max-width: 1170px;
    box-sizing: border-box; 
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;  
    grid-column-gap:10px; 
    grid-row-gap:10px;
    overflow:scroll;
    margin-bottom: 30px;
}


.skillList div{
    width: 202.5px;
    min-width: 202.5px;
    max-width: 202.5px;
    height: auto;
}

.bSkillList div{
    width: 277.5px;
    min-width: 277.5px;
    max-width: 277.5px;
    height: auto;
}

.mSkillList div{
    width: calc((100vw - 60px) / 3);
    min-width: calc((100vw - 60px) / 3);
    max-width: calc((100vw - 60px) / 3);
    height: auto;
    /* margin-bottom: 15px;  */
} 

.skillList img{
    width: 40px;
    height: 40px;
    border: 5px solid #dcdcdc; 
    border-radius: 250px;
    padding:20px;
    margin-bottom: 10px;
}

.bSkillList img{
    width: 40px;
    height: 40px;
    border: 5px solid #dcdcdc; 
    border-radius: 250px;
    padding:20px;
    margin-bottom: 10px;
}

.mSkillList img{
    width: 30px;
    height: 30px;
    border: 3px solid #dcdcdc;
    border-radius: 250px;
    padding:15px;
    margin-bottom: 10px;
}
 
.skillTitle{
    font-weight: 700;
    color: #323232;
    margin-bottom: 5px;
}

.skillContents{
    font-size: 13px;
}




.portfolioImg{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: calc(100% - 80px);
    min-height: calc(100% - 80px);  
    object-position: center;
    object-fit: cover;
    box-sizing: border-box; 
    position: absolute;
    top:0px;
}
  


.topImg{
    width: 100vw;
    height: 60vh;
    min-height: 60vh;
    max-height: 60vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
 

.topImgImg{
    top:0px;  
    position: absolute;  
    /* filter: brightness(0.5); */
    filter: brightness(0.4);
    width: 100vw;
    height: 100%;
    min-height: 300px;
    object-fit: cover;
}

.topImgDiv{
    top:0px;
    width: 100%;
    height: 100%;
    min-height: 300px;
    position: absolute;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;    
}

.topImgContents{
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    font-weight: 500;
}

.helloPulse{
    font-size: 40px;
    display: flex;
    flex-direction: row;
}
  


.typingText {
    overflow:hidden;
    white-space:nowrap;
    color:#ffffff;
    border-right:4px solid #ffffff;
    animation: 
        typing 12s steps(27) forwards, 
        cursor .8s step-end infinite;
    animation-iteration-count: infinite;
}
@keyframes cursor{
    from, to{
        border-color:transparent;
    }
    50%{
        border-color:#ffffff;
    }
}
@keyframes typing{
    0%{width:0%;}
    30%{width:100%}
    80%{width:100%}
    90%{width:0%}
    100%{width:0%}
}
 
.topImgContact{
    font-size: 20px;
    padding-top:15px;
}
 

.mainportfolio{
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding:50px;
    box-sizing: border-box; 
}

.portfolioTitle{
    width: 100%;
    max-width: 100%;
    font-size: 27px;
    color: #323232;
    padding:20px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.mPortfolioTitle div:nth-child(1){
    letter-spacing: 15px;
    padding-left:15px;
}

.portfolioTitle div:nth-child(2){
    font-size: 14px;
    font-weight: 400;
    margin-top:10px;
    text-align: center;
}

.mPortfolioTitle{
    max-width: 100%;
    font-size: 20px;
    color: #323232;
    padding:20px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} 

.portfolioTitle div:nth-child(1){
    letter-spacing: 15px;
    padding-left:15px;
}

.mPortfolioTitle div:nth-child(2){
    font-size: 13px;
    font-weight: 400;
    margin-top:10px;
    text-align: center;
}

.portfolioLine{
    height: 3px;
    width: 50px;
    margin: 30px 30px 10px 30px;
    background-color: #323232;
}

.contactLayout{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:0px 30px 20px 30px;
    box-sizing: border-box;
}
 
.contactLeft{
    width: 100%;
    display: flex;
    flex:1; 
    flex-direction: column; 
    box-sizing: border-box; 
}

.contactRight{
    width: 100%;
    display: flex;
    flex:1;
    flex-direction: column; 
    box-sizing: border-box; 
}

.contactBox{ 
    flex-direction: column; 
}

.contactBoxTitle{
    width: 100%;
    text-align: left; 
    height: 20px;
    margin-bottom: 5px;
    padding:20px 0px 10px 0px;
    color: #969696;
}

.contactBox input{
    width: 100%;
    outline: none;
    border:1px solid #eeeeee;
    height: 45px;
    min-height: 45px;
    padding:0px 10px 0px 10px;
    border-radius: 5px;
    box-sizing: border-box;
    /* width: calc(100% - 15px); */

}

.contactBox textarea{
    width: 100%;
    outline: none;
    border:1px solid #eeeeee;
    padding:12px 10px 12px 10px;
    border-radius: 5px;
    box-sizing: border-box; 
    height: 100%;
    resize:none; 
    overflow: hidden;
}

.contactStyle{
    width: 100%;
    height: fit-content;
    max-height: 100%;
    flex-direction: column;
}

.contactCategory{
    width: 100%;
}

.contactCategoryItem{
    display: block;
    float: left;
    height:45px;
    margin:0px 2px 5px 2px; 
}

.contactBottomContents{
    display: flex;
    flex-direction: column;
    width: 100%;
}
                         
.contactBottom{
    width:calc(100% - 60px);
    display:flex;
    align-items:center;
    justify-content:flex-end;
}

.contactCategoryItem .contactCkOn{
    padding:0px 12px 0px 12px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:45px;
    width:100%;
    border:1px solid #323232;
    background-color: #323232;
    box-sizing: border-box;
    color:#ffffff;
    font-size: 13px;
}

.contactCategoryItem .contactCkOff{
    padding:0px 12px 0px 12px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:45px;
    width:100%;
    background-color: #ffffff;
    border:1px solid #eeeeee;
    box-sizing: border-box;
    color:#969696;
    font-size: 13px;
}
 
.contactMsg{
    text-align: left;
    padding:10px 5px 0px 5px;
    box-sizing: border-box;
    width:100%; 
    color:#969696;
}

.contactAgreeCk{
    display: flex;
    padding:30px 5px 30px 5px;
    box-sizing: border-box;
    flex-direction: row;
    text-align: left;
    width:100%;
}

.contactAgreeCk img{
    width:18px;
    height:18px;
    margin-right:10px;
}

.contactAgreeCk >div:nth-child(1){
    display: flex;
    /* flex:1; */
    align-items: center;
}
.contactAgreeCk >div:nth-child(2){
    display: flex;
    min-width: fit-content;
    padding-left:15px;
    justify-content: flex-end;
    text-decoration: underline #969696;
    text-underline-position: under;
}

.contactCall{
    border-radius: 5px;
    width:fit-content; 
    background-color: #323232; 
    padding:15px;
    width: 150px;
    box-sizing: border-box;
    color:#ffffff;
}




.InPageTopImg{
    width: 100vw;
    height: 250px;
    min-height: 250px;
    max-height: 250px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
 

.InPageTopImgImg{
    top:0px;  
    position: absolute;  
    filter: brightness(0.5);
    width: 100vw;
    height: 100%;
    object-fit: cover;
}

.InPageTopImgDiv{
    top:0px;
    width: 100vw;
    height: 100%;
    position: absolute;
    color: #ffffff;
  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;    
}

.InPageTopImgDiv div:nth-child(1){
    font-size: 19px;
    font-weight: 500;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 2px solid #ffffff;
}


.ellipsisStyle{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    text-align: center; 
}


.portfolioItem{
    position: relative; 
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-top:7px;
    margin-bottom: 10px;
 }

 .portfolioItem:hover{
    animation-name : up;
    animation-iteration-count : 1;
    animation-direction : alternate;
    animation-duration : 0.2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
 }
 
  @keyframes up {
    0% {transform: translateY(0px);}
    100% {transform: translateY(-7px);}
  }



  

 .portfolioItemImg{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: calc(100% - 80px);
    min-height: calc(100% - 80px);  
    border-radius: 10px;
    object-position: center;
    object-fit: cover;
    box-sizing: border-box; 
    position: absolute;
    top:0px;
    border:1px solid #dcdcdc50;
 }


.portfolioItemHover{
    display: none;
}
   
.portfolioItemHoverView{
    padding:3px 7px 3px 7px;
    background-color: #323232;
    font-size: 13px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #ffffff; 
}

.portfolioItemContents{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}

.portfolioItemContentsBox{
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    line-height: 1.7;
    padding: 10px;
    box-sizing: border-box;
    /* font-size: 14px; */
}

.portfolioItemContentsTitle{
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    align-items: center;
    font-weight: 700;
}

.dialogPortfolio{ 
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 50px;
    overflow: scroll;
    left: calc((100vw - 870px) / 2);
    width: 870px;
    max-width: 870px;
    min-width: 870px;
    height: calc(100vh - 50px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffffff;
    padding:20px 20px 0px 20px;
    box-sizing: border-box;
    font-family: 'NanumSquare';
}

.mDialogPortfolio{ 
    font-size: 14px;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 30px;
    overflow: scroll;
    left: 10px; 
    width: calc(100vw - 20px);
    min-width: calc(100vw - 20px);
    max-width: calc(100vw - 20px);
    height: calc(100% - 30px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffffff;
    padding:0px 10px 0px 10px;
    box-sizing: border-box;
    font-family: 'NanumSquare';
}

.dialogPortfolioTopLayout{
    width: 100%; 
    position: fixed;
    z-index: 9999;
    display: flex; 
}

.dialogPortfolioTopLayout img{
    width: 18px;
    height: 18px;
    padding:20px 20px 30px 10px;
}

.dialogPortfolioLayout{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    max-width: 100%;  
}

.scrollIcon { 
    padding:10px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    font-size: 11px;
    align-items: center;
    color: #969696;
}

.scrollText{
    padding:0px 5px 0px 5px;
    letter-spacing: 2px;
    -webkit-animation: scrollAni 1.5s infinite;
    animation: scrollAni 1.5s infinite;
    font-size: 14px;
}

.scrollArrowStep1 { 
    padding:0px 3px 0px 3px;
    -webkit-animation: scrollAni 1.5s infinite;
    animation: scrollAni 1.5s infinite;
    box-sizing: border-box;
}

.scrollArrowStep2 { 
    padding:0px 3px 0px 3px;
    -webkit-animation: scrollAni2 1.5s infinite;
    animation: scrollAni2 1.5s infinite;
    box-sizing: border-box;
}

.scrollArrowStep3 { 
    padding:0px 3px 0px 3px;
    -webkit-animation: scrollAni3 1.5s infinite;
    animation: scrollAni3 1.5s infinite;
    box-sizing: border-box;
}

@-webkit-keyframes scrollAni {
    0% { 
        opacity: 0.8;
    }
    50% {
        opacity: 1;
    }
    100% { 
        opacity: 0.8;
    }
}
@keyframes scrollAni {
    0% { 
        opacity: 0.8;
    }
    50% {
        opacity: 1;
    }
    100% { 
        opacity: 0.8;
    }
}

@-webkit-keyframes scrollAni2 {
    0% { 
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% { 
        opacity: 0.5;
    }
}
@keyframes scrollAni2 {
    0% { 
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% { 
        opacity: 0.5;
    }
}

@-webkit-keyframes scrollAni3 {
    0% { 
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% { 
        opacity: 0;
    }
}
@keyframes scrollAni3 {
    0% { 
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% { 
        opacity: 0;
    }
}

.dialogPortfolioTitleBar{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
}

.dialogPortfolioLogoImg{
    width: 90px;
    height: 90px;
    padding-right:15px;
}

.dialogPortfolioTitleBox{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dialogPortfolioTitleName{
    margin-bottom: 2px;
    color: #323232;
    text-align: left;
}

.dialogPortfolioTitleContents{
    margin-bottom: 12px;
    text-align: left;
}

.dialogPortfolioTitleType{
    display: flex;
    flex-direction: row;
}

.dialogPortfolioTitleType .div{
    color:#969696;
    font-size: 13px;
}

.dialogPortfolioInfo{
    text-align: left;
    display: flex;
    flex-direction: column;
    padding:0px 10px 10px 10px;
}

.dialogPortfolioInfoTitle{
    color: #323232;
}

.dialogPortfolioInfoContents{
    color: #969696;
    padding:10px 0px 30px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.dialogPortfolioIcon{
    width: 30px;
    height: 30px;
}

.dialogPortfolioItem{
    margin-right: 3px;
}

.dialogPortfolioInfoBtnLayout{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin:-10px 0px 10px 0px;
}

.dialogPortfolioInfoBtn{
    width: calc(50% - 5px);
    max-width: 230px;
    margin:0px 5px 0px 0px;
    border:1px solid #eeeeee;
    border-radius: 10px;
}

.dialogPortfolioImgLayout{
    display: flex;
    margin:10px 10px 30px 10px;
    overflow-y: hidden;
    position: relative;
    box-sizing: border-box;
    width: calc(100% - 20px);
}

.dialogPortfolioMonitorImg{
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    position: absolute;
}

.dialogPortfolioImg{
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

.mainContactBtn{
    border:1px solid #ffffff90;
    border-radius: 250px;
    margin-top:80px;
    padding:10px 18px 10px 18px;
    border-radius:250;
    width:fit-content;
    font-weight:700;
}