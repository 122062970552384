
 

.inputText {
    background-color: #ffffff;
    padding:15px;
    box-sizing: border-box;
    border:1px solid #dcdcdc; 
    outline: none;
    white-space: pre-line;
    resize: none;
}

.inputText::-webkit-input-placeholder { /* 크롬 4–56 */
    color: #b7b7b7; 
 }
 .inputText:-moz-placeholder { /* 파이어폭스 4–18 */
    color: #b7b7b7; 
 }
 .inputText::-moz-placeholder { /* 파이어폭스 19–50 */
    color: #b7b7b7; 
 }
 .inputText:-ms-input-placeholder { /* 인터넷 익스플로러 10+ */
    color: #b7b7b7; 
 }
 .inputText::placeholder { /* 파이어폭스 51+, 크롬 57+ */
    color: #b7b7b7; 
 }
 
