.pcApp {
  text-align: center;
  width:100vw;
  height: calc(var(--vh, 1vh) * 100);
  min-height:calc(var(--vh, 1vh) * 100);
  max-height:calc(var(--vh, 1vh) * 100);
  overflow: hidden;  
  font-size: 15px;
}

.bigPcApp{
  text-align: center;
  width:100vw;
  height: calc(var(--vh, 1vh) * 100);
  min-height:calc(var(--vh, 1vh) * 100);
  max-height:calc(var(--vh, 1vh) * 100);
  overflow: hidden;  
  font-size: 15px;
}


.mobileApp{
  text-align: center;
  width:100vw;
  height: calc(var(--vh, 1vh) * 100);
  min-height:calc(var(--vh, 1vh) * 100);
  max-height:calc(var(--vh, 1vh) * 100);
  overflow: hidden;  
  font-size: 14px;
} 

.swiperMainLayout{
  background-color: #ffffff;
  width:100vw;
  height: 100%;
  min-height: 100%;
  left:0;
  top:0;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}
 
.fontSize15{
  font-size: 15px;
} 
 
.mobileApp .textSize1{
  /* font-size: 120%; */
  font-size:14px;
}
.mobileApp .textSize2{
  /* font-size: 200%; */
  font-size:25px;
}
.mobileApp .textSize3{
  /* font-size: 270%; */
  font-size:28px;
}
.mobileApp .korTextSize1{
  font-size: 14px;
} 
.mobileApp .korTextSize2{
  font-size: 17px;
} 
.mobileApp .korTextSize3{
  font-size: 20px;
} 
.mobileApp .korTextSize4{
  font-size: 24px; 
} 
.mobileApp .korTextSize5{
  font-size: 36px;
} 


.pcApp .textSize1{
  /* font-size: 150%; */
  font-size:18px;
} 
.pcApp .textSize2{
  /* font-size: 250%; */
  font-size:30px;
} 
.pcApp .textSize3{
  /* font-size: 350%; */
  font-size:40px;
}
.pcApp .korTextSize1{
  font-size: 15px;
}
.pcApp .korTextSize2{
  font-size: 19px;
} 
.pcApp .korTextSize3{
  font-size: 24px;
} 
.pcApp .korTextSize4{
  font-size: 35px;
} 
.pcApp .korTextSize5{
  font-size: 50px;
} 


.bigPcApp .textSize1{
  /* font-size: 150%; */
  font-size:18px;
}
.bigPcApp .textSize2{
  /* font-size: 250%; */
  font-size:30px;
}
.bigPcApp .textSize3{
  /* font-size: 350%; */
  font-size:40px;
}
.bigPcApp .korTextSize1{
  font-size: 15px;
} 
.bigPcApp .korTextSize2{
  font-size: 19px;
} 
.bigPcApp .korTextSize3{
  font-size: 24px;
} 
.bigPcApp .korTextSize4{
  font-size: 35px;
} 
.bigPcApp .korTextSize5{
  font-size: 50px;
} 


.navMenuCkOn{
  padding:20px 0px 20px 0px;
  color: #ffffff;
}
.navMenuCkOff{
  padding:20px 0px 20px 0px;
  color: #ffffff90;
}


.pcApp .dialogTopMenuLayout{ 
  height: 120px;
  min-height: 120px;
  max-height: 120px; 
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
  box-sizing: border-box;
}

.bigPcApp .dialogTopMenuLayout{ 
  height: 120px;
  min-height: 120px;
  max-height: 120px; 
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
  box-sizing: border-box;
}

.mobileApp .dialogTopMenuLayout{ 
  height: 80px;
  min-height: 80px;
  max-height: 80px; 
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
  box-sizing: border-box;
}


.pcApp .topMenuFullSizeLayout{ 
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-sizing: border-box;
}

.bigPcApp .topMenuFullSizeLayout{ 
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-sizing: border-box;
}

.mobileApp .topMenuFullSizeLayout{ 
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-sizing: border-box;
}


.pcApp .topMenuLayout{ 
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-sizing: border-box;
}

.bigPcApp .topMenuLayout{ 
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-sizing: border-box;
}

.mobileApp .topMenuLayout{ 
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-sizing: border-box;
}
 

.pcApp .topMenuHeight{ 
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  width:100%;
}

.bigPcApp .topMenuHeight{ 
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  width:100%;
}

.mobileApp .topMenuHeight{ 
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  width:100%;
}

.pcApp .mainLogo{ 
  height: auto;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.bigPcApp .mainLogo{ 
  height: auto;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.mobileApp .mainLogo{ 
  height: auto;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.pcApp .pageWidthFullSize{ 
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  padding:0px 80px 0px 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigPcApp .pageWidthFullSize{ 
  width: 100vw;
  max-width: 1700px;
  height: 100%;
  padding:0px 80px 0px 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileApp .pageWidthFullSize{ 
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  padding:0px 20px 0px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}




.pcApp .dialogWidth{ 
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding:0px 80px 0px 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigPcApp .dialogWidth{ 
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding:0px 80px 0px 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileApp .dialogWidth{ 
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding:0px 30px 0px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}











.mainLayout{
  display: flex;
  flex-direction: column; 
  width:100vw; 
  height: 100%; 
  overflow: scroll;
  box-sizing: border-box;
  color:#5d5d5d;
  align-items: center;
  position: relative;
  font-family: 'SUIT-Regular';
}

.topMenuBar{
  padding:0px 30px 0px 30px;
  box-sizing: border-box;
}
  
/* .topMenuLayout{
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  position: fixed;
  top:0px;
  left:0px;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  z-index: 1000;
  box-sizing: border-box;
}  */

.mTopMenuLayout{
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  position: fixed;
  top:0px;
  left:0px;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  z-index: 1000;
  box-sizing: border-box;
}


.pageWidth{
  width: 900px ;
  max-width: 900px ;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bPageWidth{
  width: 1200px;
  max-width: 1200px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mPageWidth{
  width: 100vw;
  max-width: 100vw;
  /* padding:15px; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageWidth .mainLogo{
  height: 40px;
  width: 100px;
}

.bPageWidth .mainLogo{
  height: 40px;
  width: 100px;
}

.mPageWidth .mainLogo{
  height: 40px;
  width: 85px;
  min-width: 85px;
  max-width: 85px;
}

.navMainLogo{
  height: 40px;
  width: 85px;
  min-width: 85px;
  max-width: 85px;
}


.bottomColumn323232{
  width: 100vw;
  
  padding: 40px 0px 40px 0px;
  box-sizing: border-box;
  background-color: #323232;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.bottomColumn323232Layout{
  color: #969696;
  display: flex;
  flex-direction: column;
}

.mBottomColumn323232Logo{
  font-size: 15px;
  color: #ffffff;
  padding:0px 0px 25px 15px;
  letter-spacing: 15px;
}

.bottomColumn323232Logo{
  font-size: 18px;
  color: #ffffff;
  padding:0px 0px 25px 15px;
  letter-spacing: 15px;
}

.mBottomColumn323232Row{
  align-items: center;
  font-size: 11px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 2;
  width: 100%;
  flex-wrap: wrap;
}

.mBottomColumn323232Row div:nth-child(2){
  margin:0px 5px 0px 5px;
  width: 1px;
  height: 10px;
  background-color: #96969650;
}

.bottomColumn323232Row{
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  width: 100%;
  flex-wrap: wrap;
}

.bottomColumn323232Row div:nth-child(2){
  margin:0px 5px 0px 5px;
  width: 1px;
  height: 10px;
  background-color: #96969650;
}

.mBottomColumn323232Column{
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 2;
  width: 100%;
  flex-wrap: wrap;
}

.bottomColumn323232Column{
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;
  width: 100%;
  flex-wrap: wrap;
}

.mBottomColumn323232Button{
  font-size: 11px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top:30px;
  color: #ffffff;
  font-weight: 700;
}

.bottomColumn323232Button{
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top:30px;
  color: #ffffff;
  font-weight: 700;
}

.bottomColumn323232Link{
  color: inherit;
  text-decoration: none;
  padding:0px 5px 0px 5px;
}

.menuBar{
  display: flex;
  flex-direction: row;
  flex:1;
  justify-content: flex-end;
}

.threeMenu{
  padding: 30px 20px 30px 40px;
}

.menuBarLogo{
  width: 25px;
  height: 25px;
}

.navMenuBar{
  border-top: 1px solid #eeeeee;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  flex:1;
  width: 100vw;
}
.navthreeMenu{
  display: flex;
  flex:1;
  align-items: center;
  justify-content: center;
  padding: 15px 0px 15px 0px;
  box-sizing: border-box;
}
 

.dialogNavBk{
  height: 100%;
  background-color: #000000ea;
  width:100vw;
  max-width: 900px;
  display: flex; 
  flex-direction: column;
  /* width:calc(100vw - 60px);
  max-width: calc(100vw - 60px); */ 
  box-sizing: border-box; 
  position: fixed;
  overflow: auto;
  top:0px;
  right:0px; 
  color: #ffffff;
  font-weight: 700;
}


.dialogNav{
  display: flex;
  flex-direction: column;
  /* width:calc(100vw - 60px);
  max-width: calc(100vw - 60px); */
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0px;
  left:0px;
  background-color: #ffffff;
}

.dialogNavTitle{
  display: flex;
  flex-direction: row;
  width:100vw;
  max-width: 100vw;
  padding:15px 30px 15px 30px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center; 
  height: 70px;
  min-height: 70px;
  max-height: 70px;
}

.menuBtnOrg{
  box-sizing: border-box;
  color: #ee8432;
  font-weight: 700;
}

.menuBtnWh{
  box-sizing: border-box;
  color: #ffffff;
  font-weight: 700;
}

.menuBtnBk{
  box-sizing: border-box;
  color: #323232;
  font-weight: 700;
}


.dialog-on{
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0px;
  flex-direction: column;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
}


.dialogLayout{
  background-color:#ffffff;
  max-height:70vh;
  overflow:scroll
}
.dialog-on >div{
  display: flex;
  justify-content: flex-start; 
  align-items: flex-start;
}
.dialog-top {
  position: fixed;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  z-index: 2001;   
  align-items: center;
  justify-content: center;
  
 box-sizing: border-box;
}


.dialog-top img {
  width:16px;
  max-width: 16px;
  height:16px;
  max-height: 16px; 
  padding:20px; 
}

.dialog-top div{ 
 display:flex;
 flex:1;
 justify-content:center;
 font-weight: 700;
 color:#323232;
 font-size: 16px;
}


.display-none{
  display: none;
}


.dialogLayout span{
  margin-top:40px;
  padding:0px 20px 0px 20px;
}

.boldtext{
  font-weight:700;
  padding-bottom:10px;
  font-size: 16px;
  color:#323232;
}

.videoFullSize{
  object-fit: cover;
  object-position: left;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  min-Height: calc(var(--vh, 1vh) * 100);
}

.mainVideoLayout{
  object-fit: cover;
  object-position: left;
  width: 100vw;
  height: 100%;
}

.mainScrollIcon{
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  width: 18px;
  min-width: 18px;
  max-width: 18px;
  border:2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 250px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.mainScrollText{
  margin-top:7px;
  font-size: 11px;
  letter-spacing: 1.3px;
  color: #ffffff;
}

.aniScrollDown{
  margin-top:3px;
  width: 2px;
  height: 2px;
  background-color: #ffffff;
  border-radius: 250px;
  animation-name : down;
  animation-iteration-count : infinite;
  /* animation-direction : alternate; */
  animation-duration : 1s;
  animation-timing-function: linear;
}
@keyframes down {
  0% {transform: translateY(0px);opacity: 1;}
  25% {transform: translateY(1px);opacity: 1}
  50% {transform: translateY(2px);opacity: 0.8}
  75% {transform: translateY(3px);opacity: 0.5}
  100% {transform: translateY(4px);opacity: 0.3} 
  100% {transform: translateY(4px);opacity: 0} 
}
 
.fontNanumSquareBold{
  font-family:'NanumSquareBold'
}

.fontNanumSquareExtraBold{
  font-family:'NanumSquareExtraBold'
} 

.fontNanumSquare{
  font-family:'NanumSquare'
}

.fontCafe24Ssurround{
  font-family: 'Cafe24Ssurround';
}

.fontGongGothicMedium{
  font-family: 'GongGothicMedium';
  font-weight: 300;
  /* 300,400,700 */
}

.fontNanumSquareRound{
  font-family: 'NanumSquareRound';
  font-weight: 700;
}

.fontSuit{
  font-family: 'SUIT-Regular';
}