 

.doubleportionMainLogo{
    height: 35%;
    width:auto;
    user-select: none;
}

.doubleportionMenuBar{
    display: flex;
    flex-direction: row;
    flex:1;
    justify-content: flex-end;
}


.doubleportionSwiper{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: flex;  
    align-items: center;
    justify-content: center;

}


.doubleportionMenu{
    user-select: none;
    cursor: pointer;
    border-radius: 250px;
    padding:5px 13px 5px 13px;
    box-sizing: border-box;
    border: 1px solid #ee8432;
    color: #ee8432;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.appContentsVideo{
    width: 95%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    clip-path:inset(1px 1px);
    /* position: absolute;
    top:0px;
    left:0px;
    z-index: 1000; */
}

.doubleportionMainPage{
    width:100vw;
    display:flex;
    align-items:flex-start;
    justify-content:center;
    height:100%;
    min-height:100%;
    max-height:100%;
    border-bottom: 1px solid #ffffff;
    
}
  
.doubleportionMainBackground {
    color: #ffffff;
    background: linear-gradient(-45deg, #ee8432, #ff606c, #edca35);
    background-size: 400% 400%;
    animation: AnimationName 3s ease infinite;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 35%;
    text-align: center;
  }
  .doubleportionMainBackground2 {
    color: #ffffff;
    /* background: linear-gradient(-45deg, #368cea, #7629ef, #50adf9); */
    background: linear-gradient(-45deg, #368cea, #7629ef, #50adf9);
    background-size: 200% 200%;
    animation: AnimationName 3s ease infinite;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 35%;
    text-align: center;
  }

  .doubleportionMainBackground3 {
    color: #ffffff;
    background: linear-gradient(-45deg, #c2326f, #5a2257, #663791, #dc3869, #cdadd1);
    background-size: 400% 400%;
    animation: AnimationName 3s ease infinite;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 35%;
    text-align: center;
  }



@keyframes AnimationName { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
   

.circle1{
    height: 10px;
    min-height: 10px;
    max-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle1 span{
    height:7px;
    width:7px;
    border-radius: 250px;
    background-color: #ee8432;    
    animation-name : down;
    animation-iteration-count : infinite; 
    animation-duration : 0.5s;
    animation-timing-function: linear;
} 

.circle2{
    height: 10px;
    min-height: 10px;
    max-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle2 span{
    height:7px;
    width:7px;
    border-radius: 250px;
    background-color: #ee8432;    
    animation-name : down;
    animation-iteration-count : infinite; 
    animation-duration : 0.5s;
    animation-delay: 0.2s;
    animation-timing-function: linear;
} 
@keyframes down {
    0% {transform: translateY(0px);}
    50% {transform: translateY(2px);}
}
 

.swiper-pagination {
	position: relative;   
	display: flex;
    height:100%;
	flex-direction: column; 
    align-items: center;
    justify-content: center;
    user-select: none;
}


.swiper-pagination-bullet{
	
	height:8px;
    width:8px;
	border-radius: 250px;
	opacity: 1; 
	outline: none; 
	background-color:#96969650; 

	margin-bottom: 10px !important;
} 
 

.swiper-pagination-bullet:hover{  
}




.swiper-pagination-bullet-active {
 
	height:10px;
    width:10px;
	border-radius:250px;
	outline: none; 
    background-color: var(--bulletColor);
}


 
  
  .one {
    opacity: 0;
    animation: dd  10s ;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-iteration-count : infinite; 
    
  }
  
  .two {
    opacity: 0;
    animation: dd  10s ;
    animation-delay: 3.3s;
    animation-fill-mode: forwards;
    animation-iteration-count : infinite; 
  }
  
  .three {
    opacity: 0;
    animation: dd  10s ;
    animation-delay: 6.6s;
    animation-fill-mode: forwards;
    animation-iteration-count : infinite; 
  } 

  
  @keyframes dd {
    
    0% {
        opacity: 0;
        transform: translate3d(0, 30%, 0);
    }
    5%{
        opacity: 1;
        transform: translateZ(0);
    }
    25%{
        opacity: 1;
        transform: translateZ(0);
    }
    33%{
        opacity: 0;
      transform: translate3d(0, 30%, 0);
    }
   
    100%{
        opacity: 0;
      transform: translate3d(0, 30%, 0);
    } 
}