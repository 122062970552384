.swiper-layout{
    position: relative; 
    width:100vw;
    height: auto;
    display: flex; 
} 

.swiper{

}


  /* pagination */
.swiper .swiper-pagination {
	position: relative; 
	height: 2px;
	margin: 0px !important;
	top: 0px !important;
	left:0px !important;
	width:20%;
	min-width: 180px;
	max-width: 20%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center; 
}


.swiper .swiper-pagination-bullet{
	width:100%;
	height:2px;
	border-radius: 250px;
	opacity: 1; 
	outline: none; 
	background-color:#dcdcdc; 

	margin: 0px !important;
} 
 

.swiper .swiper-pagination-bullet:hover{ 
}




.swiper .swiper-pagination-bullet-active {
 
	height:2px;
	border-radius:250px;
	outline: none; 
	background-color: #969696; 
	margin: 0px !important;
}




  





.swiper-mainmenu{
	position: relative; 
	height: auto;
	margin: 0px !important;
	top: 0px !important;
	right:0px !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}




.ss{
	width:7px;
	height:7px;
	border-radius: 5px;
	opacity: 1; 
	outline: none; 
	background-color: #00ffff;
	margin-bottom: 20px !important;
}
.dd{
	width:10px;
	height:10px;
	border-radius:5px;
	background-color:#ffff00;
	outline: none; 
	margin-bottom: 20px !important;
}
