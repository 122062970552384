.main{
    display: flex;
    width:100vw;
    min-height: 100vh;
    height:100%;
    box-sizing: border-box;
    flex-direction: column; 
    color:#5d5d5d;
    overflow-y: scroll;
    overflow-x: hidden;
    align-items: center;
    position: relative;

}
nav{
    width: 100vw;
    position: fixed; 
    top:0px;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}


nav ul{
    display: flex;
    flex:1;
    flex-direction: row;
    margin:0px;
    padding:0px; 
    justify-content: flex-end;
}

nav li{
    list-style-type: none;  
    color: #5d5d5d;
    font-size: 16px;
    font-weight: 700;
}

nav img{
    height:30px; 
    width:fit-content;
}


.mobileTop img{
    height:25px;
    width:fit-content;
} 

.tabletTop img{
    height:25px;
    width:fit-content;
} 


nav >div{
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
 

.bigPcTop{ 
    width:1000px;
    max-width:1000px;
    height:120px;
}

.pcTop{ 
    width:950px;
    max-width:950px;
    height:120px; 
}

.tabletTop{ 
    padding:0px 40px 0px 40px;
    height:70px; 
    width:100vw;
    max-width:100vw;
}

.mobileTop{  
    padding:0px 20px 0px 20px;
    height:70px; 
    width:100vw;
    max-width:100vw;
}




.bigPc{     
    padding:0px 20px 0px 20px;
    width:1000px;
    max-width: 1000px;
    margin-top:120px;
}

.pc{      
    padding:0px 20px 0px 20px;
    width:950px;
    max-width:950px;
    margin-top:120px; 
}

.tablet{  
    padding:0px 40px 0px 40px;
    margin-top:70px; 
    width:100vw;
    max-width: 100vw;
}

.mobile{   
    padding:0px 20px 0px 20px;
    margin-top:70px; 
    width:100vw;
    max-width: 100vw;
}









section{
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
 
section >div{
    
    box-sizing: border-box; 
    display: flex;
    align-items: center;
    justify-content: center;
}




.left-div{
    color: #ffffff;
    display: flex;
    flex-direction: column;
    flex:1; 
    text-align: left;

}

.left-div >span{
    margin-top:10px; 
    display: flex;
    flex-direction: column;
    flex:1;
}

.left-div >span:nth-child(2){ 
    align-items: flex-end;
    flex-direction: row;
    margin-top: 40px;
}


.left-div >span:nth-child(2) img{
    width:48%;
    margin-bottom: 10px;
}





.left-div >span >span:nth-child(2){
    font-weight: 700;
    margin:10px 0px 30px 0px;
}
 

.right-div{
    display: flex;
    flex:1;
}

.right-div > img {
    width:100%;
}




 
footer { 
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:25px 0px 25px 0px;
    border-top:1px solid #eeeeee;
}
footer >div{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}


footer >div >div{
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
} 


footer >div >div >span{
    margin-bottom:5px;
} 

footer a{
    font-size: 15px;
    color:#5d5d5d;
    text-decoration-line:none; 
    
}










.helpContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    width:100%;
    
    font-size: 15px;
}


.helpContentMobile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;
    width:100%;
    line-height: 2.5;
    font-size: 14px;
}

.helpContent >div:nth-child(1){
    width:100px;
    text-align: left;
}

.helpMsg{
    text-align: left;
    width:100%;
    margin: 10px 0px 30px 0px;
    color:#969696;
    font-size: 15px;
}

.helpCk{
    display: flex;
    flex-direction: row;
    text-align: left;
    width:100%;
    font-size: 14px;
}

.helpCk img{
    width:18px;
    height:18px;
    margin-right:10px;
}

.helpCk >span:nth-child(1){
    display: flex;
    align-items: center;
}
.helpCk >span:nth-child(2){
    display: flex;
    flex:1;
    justify-content: flex-end;
    text-decoration: underline #969696;
    text-underline-position: under;
}

.helpCall{
    width:100%;
    background-color: #323232;
    margin: 20px 0px 30px 0px;
    padding:15px;
    box-sizing: border-box;
    color:#ffffff;
}

.line{
    width:100vw;
    height:1px;
    background-color: #eeeeee;
}

.qnaCtgr{ 
    background-color: #ffffff;
    width:100%;
    border-left:1px solid #eeeeee;
    box-sizing: border-box;
}

.qnaCk{ 
    color:#5d5d5d;
    font-weight: 700;
    background-color: #ffe6cf;
    border-right:1px solid #eeeeee;
    border-bottom:1px solid #eeeeee;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:center;
}
.qna{
    color:#b7b7b7;
    border-right:1px solid #eeeeee;
    
    border-bottom:1px solid #eeeeee;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:center;
}

.grayBar{
    width:100vw;
    height:6px;
    border-top:1px solid #dcdcdc;
    background-color:#f3f3f3;
}

.qnaCtgrFixed{
    display:flex;
    position:fixed;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    border-top:1px solid #e8e8e8;
    background-color:#ffffff;
    font-size:15px;
}

.qnaList{
    border-bottom:1px solid #eeeeee;
    line-height: 1.7;
    width:100vw;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.qnaTitleText{
    min-width:fit-content;
    color:#969696;
}

.qnaTitleTextCk{
    min-width:fit-content;
    color:#5d5d5d;
    font-weight: 700;
}

.qnaTitleImg{
    display:flex;
    flex:1;
    justify-content:flex-end;
}

.qnaContent{
  background-color: #f3f3f3;
  border:1px solid #eeeeee;
  line-height: 1.7;
  width:100vw;
  display: flex;
  justify-content: center;
}

.qnaContent div{
    padding-left:10px;
    padding-right: 10px;
}

.dialog-on{
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0px;
    flex-direction: column;
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
}


.dialogLayout{
    background-color:#ffffff;
    max-height:70vh;
    overflow:scroll
}
.dialog-on >div{
    display: flex;
    justify-content: flex-start; 
    align-items: flex-start;
}
.dialog-top {
    position: fixed;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    z-index: 2001;   
    align-items: center;
    justify-content: center;
    
   box-sizing: border-box;
}


.dialog-top img {
    width:16px;
    max-width: 16px;
    height:16px;
    max-height: 16px; 
    padding:20px; 
}
 
.dialog-top div{ 
   display:flex;
   flex:1;
   justify-content:center;
   font-weight: 700;
   color:#323232;
   font-size: 16px;
}


.display-none{
    display: none;
}


.dialogLayout span{
    margin-top:40px;
    padding:0px 20px 0px 20px;
}

.boldtext{
    font-weight:700;
    padding-bottom:10px;
    font-size: 16px;
    color:#323232;
}